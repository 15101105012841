import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
import { useResource } from '../hooks/Resource';

enum ChessColor {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
}

export enum ChessResult {
  WHITE_WON = 'WHITE_WON',
  DRAW = 'DRAW',
  BLACK_WON = 'BLACK_WON',
}

export interface ChessMove {
  move: string;
  id: string;
  isValid: boolean;
  confidence?: number;
}

export interface User {
  id: number;
  email: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface Game {
  id: number;
  opponent: string;
  tournament?: string;
  result: ChessResult;
  myColor: ChessColor;
  moves: ChessMove[][];
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  userId: number;
  user?: User;
}

export const Home: React.FC = () => {
  const [games = []] = useResource<Game[]>(`/user/games`);

  return (
    <Container key={`games-${games.length}`}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <h2 style={{ padding: 10 }}>My Games</h2>
        {games.map((game) => {
          return <GameRow game={game} />;
        })}
      </div>
    </Container>
  );
};

const GameRow: React.FC<{ game: Game }> = ({ game }) => {
  return (
    <GameRowContainer to={`/game/${game.id}`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ marginBottom: 5 }}>
          {game.myColor} vs. {game.opponent}
        </h3>
      </div>
      <div
        style={{
          display: 'flex',
          color: 'rgba(0,0,0,0.5)',
          marginBottom: 5,
        }}
      >
        {format(new Date(game.createdAt), 'MMM d, Y')}
        <div style={{ marginLeft: 5 }} />
        {game.tournament && <div>&#x2022;</div>}
        <div style={{ marginLeft: 5 }} />
        {game.tournament}
      </div>
      <div>
        {game.result === ChessResult.WHITE_WON
          ? '1-0'
          : game.result === ChessResult.BLACK_WON
          ? '0-1'
          : '1/2-1/2'}
      </div>
    </GameRowContainer>
  );
};

const Container = styled.div`
  display: flex;
  padding: 100px;
`;

const GameRowContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #efefef;
  }
`;
