import React from 'react';
import styled from 'styled-components';

export const TermsOfUse: React.FC = () => {
  // const RedirectPage = () => {
  React.useEffect(() => {
    window.location.replace(
      'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
    );
  }, []);
  //   }

  return (
    <Container>
      <Content>
        {/* <h1>Terms of Use (EULA) for Chess Lens</h1>
        <p>
          By downloading, accessing, or using the Chess Lens app (the
          &quot;App&quot;), you agree to be bound by the following terms and
          conditions (the &quot;Terms&quot;). If you do not agree to these
          Terms, do not download, access, or use the App.
        </p>
        <ol>
          <li>License</li>
        </ol>
        <p>
          Subject to your compliance with these Terms, the App&apos;s developer
          grants you a limited, non-exclusive, non-transferable, revocable
          license to download, access, and use the App on a single device that
          you own or control. You may not distribute or make the App available
          over a network where it could be used by multiple devices at the same
          time. You may not copy, modify, distribute, sell, or lease any part of
          the App, nor may you reverse engineer or attempt to extract the source
          code of the App.
        </p>
        <ol start={2}>
          <li>User Content</li>
        </ol>
        <p>
          The App may allow you to create, upload, post, or otherwise transmit
          content, such as comments, messages, photos, or videos (collectively,
          &quot;User Content&quot;). You retain ownership of any intellectual
          property rights that you hold in your User Content. By creating,
          uploading, posting, or otherwise transmitting User Content through the
          App, you grant the App&apos;s developer a perpetual, irrevocable,
          worldwide, royalty-free, and non-exclusive license to use, reproduce,
          modify, adapt, publish, translate, create derivative works from,
          distribute, perform, and display your User Content in connection with
          the App and the developer&apos;s business. You represent and warrant
          that you have all rights necessary to grant this license and that your
          User Content does not violate any laws or these Terms.
        </p>
        <ol start={3}>
          <li>User Conduct</li>
        </ol>
        <p>
          You agree to use the App only for lawful purposes and in a manner that
          does not infringe the rights of, or restrict or inhibit the use and
          enjoyment of the App by any third party. You may not use the App to
          transmit or post any content that is illegal, harmful, threatening,
          abusive, harassing, tortious, defamatory, vulgar, obscene, libelous,
          invasive of another&apos;s privacy, hateful, or racially, ethnically,
          or otherwise offensive. You may not use the App to transmit or post
          any content that you do not have a right to transmit or post under any
          law or under any contractual or fiduciary relationships. You may not
          use the App to transmit or post any unsolicited or unauthorized
          advertising, promotional materials, &quot;junk mail,&quot;
          &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid
          schemes,&quot; or any other form of solicitation. You may not use the
          App to harm, or attempt to harm, minors in any way.
        </p>
        <ol start={4}>
          <li>Modification and Termination</li>
        </ol>
        <p>
          The App&apos;s developer reserves the right, in its sole discretion,
          to modify or terminate the App or your access to the App at any time
          and for any reason, without notice. The App&apos;s developer will not
          be liable to you or any third party for any modification, suspension,
          or termination of the App or your access to the App.
        </p>
        <ol start={5}>
          <li>Disclaimer of Warranties</li>
        </ol>
        <p>
          The App is provided on an &quot;as is&quot; and &quot;as
          available&quot; basis. The App&apos;s developer makes no
          representations or warranties of any kind, express or implied, as to
          the operation of the App or the information, content, materials, or
          products included on the App. The App&apos;s developer will not be
          liable for any damages of any kind arising from the use of the App,
          including, but not limited to, direct, indirect, incidental, punitive,
          and consequential damages.
        </p>
        <ol start={6}>
          <li>Limitation of Liability</li>
        </ol>
        <p>
          The App&apos;s developer will not be liable for any damages of any
          kind arising from the use of the App, including, but not limited to,
          direct, indirect, incidental, punitive, and consequential damages. The
          App&apos;s developer will not be liable for any damages resulting from
          loss of use, data, or profits, or from any unauthorized access to or
          alteration of your transmissions or data.
        </p>
        <ol start={7}>
          <li>Indemnification</li>
        </ol>
        <p>
          You agree to indemnify and hold the App&apos;s developer and its
          affiliates, officers, agents, and employees harmless from any claim or
          demand, including reasonable attorneys&apos; fees, made by any third
          party due to or arising out of your use of the App, your violation of
          these Terms, or your violation of any rights of another.
        </p>
        <ol start={8}>
          <li>Governing Law</li>
        </ol>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the State of Tennessee, without giving effect to any
          principles of conflicts of law. You agree to submit to the personal
          and exclusive jurisdiction of the courts located within the county of
          Davidson in the State of Tennessee.
        </p>
        <ol start={9}>
          <li>Miscellaneous</li>
        </ol>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable, that provision shall be enforced to the maximum extent
          possible, and the remaining provisions shall remain in full force and
          effect. The failure of the App&apos;s developer to exercise or enforce
          any right or provision of these Terms shall not constitute a waiver of
          such right or provision. These Terms constitute the entire agreement
          between you and the App&apos;s developer and govern your use of the
          App, superseding any prior agreements between you and the App&apos;s
          developer.
        </p>
        <ol start={10}>
          <li>Contact Information</li>
        </ol>
        <p>
          If you have any questions or concerns about these Terms, please
          contact the App&apos;s developer at miltonian33@gmail.com.
        </p> */}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 100px;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;
