import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Navbar: React.FC = () => {
  return (
    <Container>
      <LogoContainer to={'/'}>
        <img
          src={require('../images/chess-lens-logo-small.png')}
          alt={'Chess Lens Logo'}
        />
        <div className="logo-text">Chess Lens</div>
      </LogoContainer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background: white;
  z-index: 2;
  padding: 20px 50px;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;

  & img {
    height: 40px;
    border-radius: 10px;
  }

  & .logo-text {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
  }
`;
