import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './pages/Login';
import { ConfigProvider } from 'antd';
import { Toaster } from 'react-hot-toast';
import { GamePage } from './pages/GamePage';
import { Navbar } from './components/Navbar';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfUse } from './pages/TermsOfUse';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0D7377',
        },
      }}
    >
      <Toaster containerStyle={{ textAlign: 'center', fontSize: 12 }} />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/game/:gameId" element={<GamePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfUse />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
