import React from 'react';
import styled from 'styled-components';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Privacy Policy</h1>
        <p>Last updated: 12/26/2022</p>
        <p>
          This Privacy Policy explains how Chess Lens and its affiliates (“we”,
          “us”, or “our”) collect, use, and share information about you when you
          use our mobile application (the “App”) and any other related services
          that we may provide (collectively, the “Services”).
        </p>
        <p>
          By using the Services, you agree to the collection, use, and sharing
          of your information as described in this Privacy Policy. If you do not
          agree with our policies and practices, do not use the Services.
        </p>
        <p>
          We may change our Privacy Policy from time to time. We encourage you
          to review the Privacy Policy whenever you access the Services to stay
          informed about our information practices and the choices available to
          you.
        </p>
        <h2>Information We Collect and How We Collect It</h2>
        <p>
          We collect information about you when you use the Services. The
          information we collect may include:
        </p>
        <ul>
          <li>
            Personal information you provide to us: We may collect personal
            information about you when you create an account, register for the
            Services, or otherwise interact with the Services. This information
            may include your name, email address, and phone number.
          </li>
          <li>
            Information we collect automatically: When you use the Services, we
            may automatically collect certain information about your device and
            your use of the Services. This information may include your IP
            address, device identifier, operating system and version, device
            settings, and information about how you interact with the Services.
          </li>
          <li>
            Information from third parties: We may also receive information
            about you from third parties, such as social media platforms,
            advertising networks, and analytics providers.
          </li>
        </ul>
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect to provide, maintain, and improve
          the Services, and to develop new products and services. We may also
          use the information for research and analytics purposes, to
          personalize and improve your experience with the Services, and for
          marketing and advertising purposes.
        </p>
        <h2>Sharing Your Information</h2>
        <p>
          We may share your information with third parties for the following
          purposes:
        </p>
        <ul>
          <li>
            To provide, maintain, and improve the Services: We may share your
            information with third parties who assist us in operating the
            Services, such as hosting and data storage providers.
          </li>
          <li>
            For research and analytics purposes: We may share your information
            with third parties for research and analytics purposes, such as to
            understand how people use the Services.
          </li>
          <li>
            For marketing and advertising purposes: We may share your
            information with third parties for marketing and advertising
            purposes, such as to show you targeted ads or to measure the
            effectiveness of our marketing campaigns.
          </li>
          <li>
            As required by law: We may disclose your information if we are
            required to do so by law, or if we believe that such action is
            necessary to comply with legal processes, to protect and defend the
            rights or property of Chess Lens, or to protect the personal safety
            of users of the Services.
          </li>
        </ul>
        <h2>Your Choices</h2>
        <p>
          You have the following choices regarding the information you provide
          to us and the information we collect about you:
        </p>
        <ul>
          <li>
            You can choose not to provide certain information to us: Some
            information is optional. If you choose not to provide certain
            information, you may not be able to use all of the features of the
            Services.
          </li>
          <li>
            You can control the information we collect from third parties: You
            can adjust your privacy settings on third party platforms, such as
            social media platforms, to control the information we collect from
            those platforms.
          </li>
          <li>
            You can control the information we collect automatically: You can
            adjust your device settings, such as your browser settings, to
            control the information we collect automatically.
          </li>
        </ul>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 100px;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;
