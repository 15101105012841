import React from 'react';
import styled from 'styled-components';
import 'react-chessground/dist/styles/chessground.css';
import { useParams } from 'react-router-dom';
import { useResource } from '../hooks/Resource';
import { ChessMove, ChessResult, Game } from './Home';
import { Chessboard } from 'react-chessboard';
import { Chess, Move } from 'chess.js';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
// const engine = require('stockfish.js');

// const stockfish = require('stockfish.js').Stockfish();
// const engine = new Worker('stockfish.js');
// const wasmSupported =
//   typeof WebAssembly === 'object' &&
//   WebAssembly.validate(
//     Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
//   );

// const engine = new Worker(wasmSupported ? 'stockfish.wasm.js' : 'stockfish.js');
// engine.addEventListener('message', function (e: any) {
//   console.log(e.data);
// });

const formatMove = (str: string) => {

  if(!str) return ''
  const pawnPrefix = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']
  const otherPiecePrefix = ['b', 'k', 'n', 'r', 'q']
  if (str.length === 2) return str.toLowerCase() || str
  if (str.length === 3) {
    if (!pawnPrefix.includes(str[0].toLowerCase())) {
      return (str[0]?.toUpperCase() + str?.slice(1)?.toLowerCase()) || str
    }
    if (!otherPiecePrefix.includes(str[0].toLowerCase()))
      return str?.toLowerCase() || str
  }
  return (str[0] + str?.slice(1)?.toLowerCase()) || str
}
export const GamePage: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();

  const [gameResp] = useResource<Game>(`/user/game/${gameId}`);
  const [game, setGame] = React.useState<Chess>(new Chess());
  const [fullGame, setFullGame] = React.useState<Chess>(new Chess());
  const [moveObj, setMoveObj] = React.useState<{
    moveIdx: number;
    colorToMoveIdx: number;
  }>({ moveIdx: -1, colorToMoveIdx: 1 });
  const [pgnObj, setPgnObj] = React.useState<{ [key: string]: string }>({});
  const [boardWidth, setBoardWidth] = React.useState<number>(450)

  React.useEffect(() => {
    makeAMove();
  }, [JSON.stringify(moveObj)]);

  

  React.useEffect(() => {
    const gameCopy = Object.assign(
      Object.create(Object.getPrototypeOf(fullGame)),
      fullGame
    );
    if (!gameResp?.moves?.length) {
      return;
    }
    fullGame.loadPgn(
      gameResp.moves
        .map(
          (m, idx) => {
            const moveNum = idx + 1
            const firstMove = formatMove(m[0].move)
            const secondMove = formatMove(m.length > 1 ? m[1].move : '')
            console.log({firstMove, secondMove})
            return `${moveNum}. ${firstMove} ${secondMove}`
          }
        )
        .join(' ')
    );
    // game.loadPgn('1. d4 d5 2. c4 e6 3. Nc3 Nf6');
  }, [gameResp]);


  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setBoardWidth(300);
      } else {
        setBoardWidth(450);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!gameResp || !game) {
    return <Container></Container>;
  }

  function makeAMove(/*move: string Move */) {
    if (!gameResp?.moves) {
      return;
    }
    const gameCopy = Object.assign(
      Object.create(Object.getPrototypeOf(game)),
      game
    );

    if (`${moveObj.moveIdx}-${moveObj.colorToMoveIdx}` === `-1-1`) {
      // first move
      gameCopy.reset();
      setGame(gameCopy);
      return;
    }
    if (pgnObj[`${moveObj.moveIdx}-${moveObj.colorToMoveIdx}`]) {
      const pgn = pgnObj[`${moveObj.moveIdx}-${moveObj.colorToMoveIdx}`];
      console.log({ pgn });
      gameCopy.loadPgn(pgn);
      setGame(gameCopy);
      return;
    }
    const move =
      gameResp?.moves?.length > moveObj.moveIdx &&
      gameResp.moves[moveObj.moveIdx].length >= moveObj.colorToMoveIdx &&
      formatMove(gameResp.moves[moveObj.moveIdx][moveObj.colorToMoveIdx]?.move);
    console.log(`moving`, move);
    // const gameCopy = { ...game } as Chess;

    setPgnObj((o) => ({
      ...o,
      [`${moveObj.moveIdx}-${moveObj.colorToMoveIdx}`]: gameCopy.pgn(),
    }));

    const result = gameCopy.move(move);
    // game.loadPgn(gameCopy.pgn);

    setGame(gameCopy);

    // console.log({ fen: gameCopy.fen() });

    // engine.postMessage(`position fen ${gameCopy.fen()}`);
    // engine.postMessage('setoption name Skill Level value 20');
    // engine.postMessage('go depth 20');
    // engine.postMessage('stop');
    // }
    // stockfish.postMessage(`position fen ${gameCopy.fen()}`);
    // stockfish.postMessage('go depth 20');

    // engine.onmessage = function (event: any) {
    //   console.log({ event });
    //   if (event && typeof event === 'object') {
    //     const score = parseInt(event.data.split(' ')[9]);
    //     console.log(`Move  score: ${score}`);
    //   }
    // };

    return result; // null if the move was illegal, the move object if the move was legal
  }

  const goToBeginning = () => {
    setMoveObj({ moveIdx: -1, colorToMoveIdx: 1 });
  };

  const goToEnd = () => {
    // game.loadPgn(fullGame.pgn());
    setMoveObj({
      moveIdx: gameResp.moves.length - 1,
      colorToMoveIdx:
        gameResp.moves[gameResp.moves.length - 1].length === 2 ? 1 : 0,
    });
  };

  const onNext = () => {
    if (moveObj.colorToMoveIdx === 1) {
      // black is moving
      if (moveObj.moveIdx > gameResp.moves.length) {
        // make sure there are still more moves left to play
        return;
      }
      setMoveObj((m) => ({ colorToMoveIdx: 0, moveIdx: m.moveIdx + 1 }));
    } else if (moveObj.colorToMoveIdx === 0) {
      // white is moving
      const move = gameResp.moves[moveObj.moveIdx];
      if (!move) {
        return;
      }
      if (move.length < 2) {
        // no move to play
        return;
      }
      setMoveObj((m) => ({ colorToMoveIdx: 1, moveIdx: m.moveIdx }));
    }
  };

  const onPrevious = () => {
    let { moveIdx, colorToMoveIdx } = moveObj;
    if (moveObj.colorToMoveIdx === 0) {
      // white is moving
      if (moveObj.moveIdx === -1) {
        // make sure we arent on the first move
        return;
      }
      // colorToMoveIdx = 1;
      // moveIdx = moveIdx - 1;
      setMoveObj((m) => ({ colorToMoveIdx: 1, moveIdx: m.moveIdx - 1 }));
    } else if (moveObj.colorToMoveIdx === 1) {
      // black is moving
      const move = gameResp.moves[moveObj.moveIdx];
      if (!move) {
        return;
      }
      if (move.length < 1) {
        // no move to play
        return;
      }
      setMoveObj((m) => ({ colorToMoveIdx: 0, moveIdx: m.moveIdx }));
      // colorToMoveIdx = 0;
    }
  };
  const onSelectMove = () => { };

  // const Board = chess.board();
  return (
    <Container>
      <Content>
        <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'start' }}>
          <LeftColumn>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 style={{ marginBottom: 5 }}>
                {gameResp.myColor} vs. {gameResp.opponent}
              </h3>
            </div>
            <div
              style={{
                display: 'flex',
                color: 'rgba(0,0,0,0.5)',
                marginBottom: 5,
              }}
            >
              {format(new Date(gameResp.createdAt), 'MMM d, Y')}
              <div style={{ marginLeft: 5 }} />
              {gameResp.tournament && <div>&#x2022;</div>}
              <div style={{ marginLeft: 5 }} />
              {gameResp.tournament}
            </div>
            <div>
              {gameResp.result === ChessResult.WHITE_WON
                ? '1-0'
                : gameResp.result === ChessResult.BLACK_WON
                  ? '0-1'
                  : '1/2-1/2'}
            </div>
            <div style={{ marginTop: 10 }} />
            {gameResp.moves.map((set, idx) => {
              return <MoveRow moveSet={set} moveIdx={idx} />;
            })}
          </LeftColumn>
          <RightColumn>
            <div style={{ width: boardWidth }}>
              <Chessboard
                boardWidth={boardWidth}
                // key={`board-${moveObj.moveIdx}-${moveObj.colorToMoveIdx}`}

                position={game.fen()}
              // on
              // onPieceDrop={(sourceSquare, targetSquare) => {
              // return false;
              // const move = makeAMove({
              //   from: sourceSquare,
              //   to: targetSquare,
              //   promotion: 'q', // always promote to a queen for example simplicity
              //   color: 'w',
              //   piece: 'p',
              //   flags: '',
              //   san: '',
              // });
              // console.log({ sourceSquare, targetSquare, move });
              // // illegal move
              // if (move === null) return false;
              // setMoveIdx((i) => i + 1);
              // // setTimeout(makeRandomMove, 200);
              // return true;
              // }}
              />
              <ControlsContainer>
                <StyledButton
                  size={'large'}
                  icon={
                    <div>
                      <LeftOutlined />
                      <LeftOutlined />
                    </div>
                  }
                  onClick={goToBeginning}
                />
                <div style={{ width: 10 }} />
                <StyledButton
                  size={'large'}
                  icon={<LeftOutlined />}
                  onClick={onPrevious}
                />
                <div style={{ width: 10 }} />
                <StyledButton
                  size={'large'}
                  icon={<RightOutlined />}
                  onClick={onNext}
                />
                <div style={{ width: 10 }} />
                <StyledButton
                  size={'large'}
                  icon={
                    <div>
                      <RightOutlined />
                      <RightOutlined />
                    </div>
                  }
                  onClick={goToEnd}
                />
              </ControlsContainer>
              <ControlsContainer>
                <CopyToClipboard
                  text={fullGame.pgn()}
                  onCopy={() =>
                    toast('Copied!', {
                      style: {
                        color: 'white',
                        background: 'var(--primary)',
                        fontSize: 16,
                        fontWeight: 'bold',
                      },
                    })
                  }
                >
                  <Button>Copy PGN</Button>
                </CopyToClipboard>
                <div style={{ width: 10 }} />
                <CopyToClipboard
                  text={game.fen()}
                  onCopy={() =>
                    toast('Copied!', {
                      style: {
                        color: 'white',
                        background: 'var(--primary)',
                        fontSize: 16,
                        fontWeight: 'bold',
                      },
                    })
                  }
                >
                  <Button>Copy FEN</Button>
                </CopyToClipboard>
              </ControlsContainer>
            </div>
          </RightColumn>
        </div>
      </Content>
    </Container>
  );
};

const MoveRow: React.FC<{ moveSet: ChessMove[]; moveIdx: number }> = ({
  moveSet,
  moveIdx,
}) => {
  return (
    <MoveRowContainer>
      <MoveNumber>{moveIdx + 1}</MoveNumber>
      <WhiteMoveWrapper>{formatMove(moveSet[0].move)}</WhiteMoveWrapper>
      <BlackMoveWrapper>
        {formatMove(moveSet.length >= 2 ? moveSet[1].move : '')}
      </BlackMoveWrapper>
    </MoveRowContainer>
  );
};

const Container = styled.div`
  display: flex;
  padding: 50px;
  padding-top: 100px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 15px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 30px;
  overflow-y: scroll;
  max-height: 470px;
  min-width: 300px;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-top: 25px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  // border: 0;
  // font-size: 30px;
`;

const MoveRowContainer = styled.div`
  display: flex;
`;
const MoveNumber = styled.div`
  display: flex;
  flex: 1;
  background: #efefef;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
const WhiteMoveWrapper = styled.div`
  display: flex;
  flex: 4;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #efefef;
  }
`;
const BlackMoveWrapper = styled.div`
  display: flex;
  flex: 4;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #efefef;
  }
`;
