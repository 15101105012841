import { Button, Input, Tabs } from 'antd';
import { stringify } from 'querystring';
import React from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import { CACHED_USER, makeRequest } from '../hooks/Resource';

enum TabEnum {
  LOGIN,
  REGISTER,
}

export const Login: React.FC = () => {
  const [values, setValues] = React.useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });
  const login = async () => {
    const resp = await makeRequest<{ token: string } | { error: string }>(
      `/auth/login`,
      'POST',
      values
    );
    console.log({ resp });
    if ('error' in resp) {
      toast(resp.error);
      return;
    }
    localStorage.setItem(CACHED_USER, resp.token);
    window.location.href = '/';
  };
  const register = async () => {
    const resp = await makeRequest<{ token: string } | { error: string }>(
      `/auth/register`,
      'POST',
      values
    );
    console.log({ resp });
    if ('error' in resp) {
      toast(resp.error);
      return;
    }
    localStorage.setItem(CACHED_USER, resp.token);
    window.location.href = '/';
  };

  return (
    <Container>
      <LoginCard>
        <LogoContainer>
          <img
            src={require('../images/chess-lens-3.png')}
            alt={'Chess Lens Logo'}
          />
          <div className="logo-text">Chess Lens</div>
        </LogoContainer>
        <Tabs style={{ width: '100%', color: 'white' }}>
          <Tabs.TabPane tab={'Log In'} key={TabEnum.LOGIN}>
            <div className="input-container">
              Email
              <Input
                onChange={(e) =>
                  setValues((v) => ({ ...v, email: e.target.value }))
                }
                placeholder={'Email'}
              />
            </div>

            <div className="input-container">
              Password
              <Input
                onChange={(e) =>
                  setValues((v) => ({ ...v, password: e.target.value }))
                }
                placeholder={'Password'}
                type={'password'}
              />
            </div>
            <div style={{ marginTop: 30 }} />
            <div className="input-container">
              <Button
                style={{
                  fontWeight: 'bold',
                  background: 'white',
                  color: 'var(--primary)',
                }}
                onClick={login}
              >
                Submit
              </Button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Register'} key={TabEnum.REGISTER}>
            <div className="input-container">
              Email
              <Input
                onChange={(e) =>
                  setValues((v) => ({ ...v, email: e.target.value }))
                }
                placeholder={'Email'}
              />
            </div>

            <div className="input-container">
              Password
              <Input
                onChange={(e) =>
                  setValues((v) => ({ ...v, password: e.target.value }))
                }
                placeholder={'Password'}
                type={'password'}
              />
            </div>
            <div style={{ marginTop: 30 }} />
            <div className="input-container">
              <Button
                style={{
                  fontWeight: 'bold',
                  background: 'white',
                  color: 'var(--primary)',
                }}
                onClick={register}
              >
                Submit
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </LoginCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: #fff;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;

  & img {
    height: 60px;
  }

  & .logo-text {
    font-weight: bold;
    font-size: 22px;
  }
`;

const LoginCard = styled.div`
  background: #fff;
  background: var(--primary);
  color: white;
  display: flex;
  border-radius: 20px;
  min-width: 400px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;

  & .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px;
    font-weight: bold;
  }
  & input {
    margin: 5px 0px;
    font-weight: regular;
  }

  &
    :where(.css-dev-only-do-not-override-rurmnv).ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn,
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    font-weight: bold;
  }
  & .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: white;
  }
`;
